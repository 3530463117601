.testimonial-slider-wrapper {
  width: 100%;
  overflow: hidden;
  padding: 0;
  direction: rtl;
}

.testimonial-slider {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 0;
}

.testimonial-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.slide-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 800px;
  text-align: center;
}

.slide-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.slide-icon {
  width: 80px;
  height: 80px;
  object-fit: contain;
  margin-bottom: 15px;
}

.title {
  font-size: 44px;
}

.slide-title {
  font-size: 30px;
  font-weight: bold;
  color: var(--color-white);
  margin: 0;
}

.section-heading {
  margin-bottom: -50px !important;
}

.slide-description {
  font-size: 20px;
  line-height: 1.6;
  color: var(--color-gray-4);
  margin-bottom: 30px;
}

.slide-image-container {
  width: 100%;
  max-width: 600px;
  margin-top: 20px;
}

.slide-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(255, 255, 255, 0.3);
}

// Custom styles for react-slick
.slick-slider {
  direction: rtl;
}

.slick-list {
  margin: 0 auto;
}

.slick-slide {
  direction: rtl;
}

.slick-slide>div {
  margin: 0 auto;
  max-width: 800px;
}

.slick-dots {
  bottom: -10px;
  direction: rtl;
  color: var(--color-white);
}

.slick-prev,
.slick-next {
  z-index: 1;
  width: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: rgba(255, 255, 255, 1);
  }

  &::before {
    color: #333;
    font-size: 24px;
  }
}

.slick-prev {
  right: 10px;
  left: auto;

  &::before {
    content: '→';
  }
}

.slick-next {
  left: 10px;
  right: auto;

  &::before {
    content: '←';
  }
}

@media (max-width: 768px) {
  .slide-title {
    font-size: 20px;
  }

  .slide-description {
    font-size: 14px;
  }

  .slide-image-container {
    max-width: 100%;
  }
}

.slick-dots li button:before {
  color: white;
  opacity: 0.5;
}

.slick-dots li.slick-active button:before {
  color: white;
  opacity: 1;
}