/* Pricing Card Hover State */
.pricing-table:hover {
  background-color: var(--color-primary);
  /* Blue card background on hover */

  .btn-business {
    background: #ffd700;
    /* Bright yellow for contrast */
    color: #000000;
    /* Black text for readability */
    box-shadow: 0 6px 20px rgba(255, 215, 0, 0.5);
    /* Yellow shadow effect */
    transform: translateY(-2px);
    /* Lift effect for button */
    font-size: 22px;
    /* Ensure text remains enlarged */
    transition: all 0.3s ease;
    /* Smooth transition */
  }
  .price {
    color: white;
  }
}

/* Enhanced Professional Button */
.btn-business {
  background: linear-gradient(90deg, #007bff, #0056b3);
  /* Default gradient */
  color: #ffffff;
  font-size: 20px;
  /* Larger font size */
  font-weight: bold;
  padding: 16px 50px;
  /* Adjust padding for a larger button */
  border-radius: 50px;
  border: none;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  display: inline-block;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.3s ease;

  &:hover {
    background: #ffffff;
    /* White background on button hover */
    color: #007bff;
    /* Blue text for contrast */
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.25);
    transform: translateY(-2px);
    /* Slight lift effect */
    font-size: 22px;
    /* Text enlarges on hover */
  }

  &:active {
    transform: translateY(0);
    /* Reset on click */
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
  }
}

.price {
  color: var(--color-primary);
  &:hover {
    color: white;
  }
}
