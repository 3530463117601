/* Small Button */
.btn-register-small {
    /* Base styles */
    display: inline-block;
    text-align: center;
    font-weight: bold;
    border: none;
    cursor: pointer;
    transition: background 0.1s ease, color 0.1s ease, transform 0.1s ease, box-shadow 0.1s ease;

    background: linear-gradient(90deg, #007bff, #0056b3);
    color: #ffffff;
    font-size: 14px;
    /* Smaller font size */
    padding: 10px 30px;
    /* Adjust padding for a smaller button */
    border-radius: 30px;
    /* Smaller border radius */
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);



    /* Hover effect */
    &:hover {
        background: #ffffff;
        /* White background on hover */
        color: #007bff;
        /* Blue text on hover */
        box-shadow: 0 5px 12px rgba(0, 0, 0, 0.25);
        transform: translateY(-2px);
        /* Slight lift effect */
    }

    & * {
        color: inherit;
        transition: color 0.1s ease;
    }

    /* Active effect */
    &:active {
        transform: translateY(0);
        /* Reset on click */
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    }

    text-decoration: none;
    /* Ensure no underline */
    outline: none;
    /* Remove focus outline */
}

@media (max-width: 768px) {
    .btn-register-small {
        all: unset;

        line-height: 50px;
        font-weight: bold;
    }
}