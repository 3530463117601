.promo-container {
    max-width: 800px;
    text-align: center;
}

.title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: #1a202c;
}

.subtitle {
    font-size: 1.1rem;
    color: #4a5568;
    margin-bottom: 2rem;
    line-height: 1.6;
}

.cta-container {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.cta-button {
    display: inline-block;
    background-color: #4299e1;
    color: white;
    font-size: 1.2rem;
    font-weight: bold;
    padding: 1rem 2rem;
    border-radius: 8px;
    text-decoration: none;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    position: relative;
    z-index: 1;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.cta-button:hover {
    background-color: #3182ce;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.promo-tag {
    margin-top: 0.5rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    text-align: center;
    background-color: #faf089;
    color: #744210;
    padding: 0.3rem 0.8rem;
    border-radius: 20px;
    font-size: 0.9rem;
    font-weight: bold;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    position: relative;
    z-index: 2;
}

.promo-tag:hover {
    transform: scale(1.05);
}

.promo-icon {
    margin-right: 0.5rem;
}

.promo-text {
    word-wrap: break-word;
    hyphens: auto;
}

@media (max-width: 768px) {
    .title {
        font-size: 2rem;
    }

    .subtitle {
        font-size: 1rem;
    }

    .cta-button {
        font-size: 1rem;
        padding: 0.8rem 1.5rem;
    }

    .promo-tag {
        font-size: 0.8rem;
    }
}